import React, { useState,useEffect } from 'react';
import { Button,Grid } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { useTranslation } from "react-i18next";
import {apiUsers} from '../../services/api/users/User';

const RemoveUserComponent = (props) => {
    const { t, i18n, ready } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    
    useEffect(() => {
      
    }, []);

    const removeUser = async () => {
        let result = await apiUsers.removeMyAccount();
        if(result.ok)
        {
            props.logOff();
        }
    };

    return (
        <Dialog open={props.isRemoveUser} onClose={()=>props.setIsRemoveUser(false)} style={{padding:"10px"}} className='super-width'>
            <DialogContent>
                <DialogTitle className="left-align">
                    Usuń moje konto
                </DialogTitle>
                <DialogContentText className="left-align">
                    <p>Oświadczam, że jestem świadomy, że usuwając swoje konto tracę dostęp do wszystkich swoich dokumentów i wszystkie moje dane zostaną trwale usunięte z serwisu. <br/>
                    Usunięcie konta jest jednoznaczne z anulowaniem subskrpycji od kolejnego okresu rozliczeniowego.</p>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Grid container spacing={2} className="right-side action-margin mobile-buttons">
                    <a className="button button-cancel" fullWidth onClick={()=>props.setIsRemoveUser(false)}>{t("user_text12")}</a>&nbsp;&nbsp;
                    <Button className="button" onClick={()=>removeUser()}>Usuń konto</Button>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

export default RemoveUserComponent;