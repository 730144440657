import React, { useState, useEffect } from 'react';
import { Button,Grid } from '@mui/material';
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

const PayComponent = (props) => {
    const { register,  getValues, setValue } = useForm();
    const { t, i18n, ready } = useTranslation();
    
    useEffect(() => {
        //if(document.getElementById('payu-card-number').children.length>0)
        //{
            //document.getElementById('payu-card-number').children[0].style.width='300px';
            //document.getElementById('payu-card-date').children[0].style.width='200px';
            //document.getElementById('payu-card-cvv').children[0].style.width='200px';
        //}
    }, []);

    return (
            <div>
                {props.isOpenPay && 
                <div className="popup">
                    <Grid container>
                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                                <Grid item xs={11}>
                                    <h5>Twój okres próbny dobiega końca</h5>
                                </Grid>
                                <Grid item xs={1} className="right-element">
                                    <a className="x" onClick={()=>{props.setIsBlur(false);props.setIsOpenPay(false);}}></a>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <p>Za {props.isHaveToPaid<1 ? '0 dni': (props.isHaveToPaid===1 ? '1 dzień': props.isHaveToPaid+' dni')} Twój okres próbny dobiegnie końca. Aby móc dalej korzystać z serwisu konieczne jest podpięcie karty kredytowej. Możesz zrezygnować w dowolnym momencie.</p>        
                        </Grid>
                        <Grid item xs={12}>
                            <Button onClick={()=>{props.setIsOpenPayLoad(true);props.setIsOpenPay(false);document.getElementById('payload').style.display='';}}>Przejdż do płatności</Button>
                        </Grid>
                    </Grid>
                </div>
                }
            </div>
    );
}

export default PayComponent;