import React from "react";
const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

export class AuthVerify {

    constructor() {
        this.verifyUser = () =>{
            const user = localStorage.getItem("token");
            if (user && user!=='undefined') {
              const decodedJwt = parseJwt(user);
              if (decodedJwt.exp * 1000 < Date.now()) {
                  localStorage.setItem('token', '');
                  localStorage.setItem('fullName', '');
                  localStorage.setItem('role', '');
                  localStorage.setItem('email', '');
                  localStorage.setItem('avatar', '');
                  window.location.href = "/";
              }
              else
              {
                localStorage.setItem('documentId', '');
              } 
            }
            else
            {
              let email='';
              if(window.location.search.indexOf('login=')>=0)
              {
                  email = window.location.search.substring(window.location.search.indexOf('login=')+6,window.location.search.length);
              }
              localStorage.setItem('token', '');
              localStorage.setItem('fullName', '');
              localStorage.setItem('role', '');
              localStorage.setItem('email', '');
              localStorage.setItem('avatar', '');
              if(email!=='')
              {
                  window.location.href = "/login?login="+email;
              }
              else
              {
                  window.location.href = "/";
              }
            }
        }
    }
};

export const authVerify = new AuthVerify({});