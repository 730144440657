import React, { useState, useEffect } from 'react';
import { Button,Grid,  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,TextField, Checkbox} from '@mui/material';
import { useForm } from "react-hook-form";
import {apiDocument} from '../../services/api/documents/Document';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import TemplateComponent from '../template/TemplateComponent';
import { useTranslation } from "react-i18next";

const TemplatesComponent = (props) => {
    const [items, setItems] = useState([]);
    const [validationMessage, setValidationMessage] = useState('');
    const { t, i18n, ready } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [editTemplate, setEditTemplate] = useState(null);
    const [id, setIdValue] = useState(-1);

    useEffect(() => {
        async function fetchData() {
            let templates = await apiDocument.templates();
            if(templates)
            {
                setItems(templates.items);
            }
        }
        fetchData();
    }, []);

    const refreshItems = async () => {
        let templates = await apiDocument.templates();
        if(templates!==null)
        {
            setItems(templates.items);
        }
    }

    const removeUser = async () => {
        await apiDocument.removeTemplate({id: id});
        await refreshItems();
        setOpenDelete(false);
    };

    const showRemove = async (id) => {
        setIdValue(id);
        setOpenDelete(true);
    };

    const handleClose = async () => {
        let selectedItems = items.filter(d=>d.isChecked);
        if(selectedItems.length>0)
        {
            window.location.href = "/create?templateId="+selectedItems[0].id;
            props.setIsOpenTemplates(false);
        }
    };

    const handleCloseExit = async () => {
        props.setIsOpenTemplates(false);
    };

    const add = async () => {
        props.setIsOpenTemplates(false);
        props.setIsOpenDocuments(true);
    }

    const edit = async (id) => {
        props.setIsOpenTemplates(false);
        props.setIsOpenDocuments(true);
        const template = await apiDocument.getTemplate(id);
        if(template)
        {
            setEditTemplate(template);   
        }
    }

    return (
        <div>
            <Dialog open={props.isOpenTemplates} onClose={handleCloseExit} style={{padding:"10px"}} maxWidth="lg" fullWidth>
                <div className='dialog-user'>
                    <DialogTitle>
                        {t("template_text1")}
                    </DialogTitle>
                    <DialogContent>
                    <DialogContentText>
                        <Grid container>
                            <Grid item xs={4} className='left-align'>
                                <h2>{t("template_text2")}</h2>
                            </Grid>
                            <Grid item xs={6}>
                                
                            </Grid>
                            <Grid item xs={2}>
                                <Button className="button button-template-mobile-add" onClick={()=>add()}>+ {t("template_text3")}</Button>
                            </Grid>
                        </Grid>
                        <br/>
                        <br/>
                        <Grid>
                            <TableContainer className="document-list user user-list ">
                                <Table aria-labelledby="tableTitle" aria-label="enhanced table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left"></TableCell>
                                            <TableCell align="left">{t("template_text4")}</TableCell>
                                            <TableCell align="left" className="not-mobile">{t("template_text5")}</TableCell>
                                            <TableCell align="left" style={{width:'100px'}}></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {items.map((row, index) => {
                                            return (
                                            <TableRow key={index}>
                                                <TableCell padding="checkbox">
                                                    <Checkbox color="primary" checked={row.isChecked} onClick={(e)=>{row.isChecked = e.target.checked; setItems([...items]);}}/>
                                                </TableCell>
                                                <TableCell align="left">{row.name}</TableCell>
                                                <TableCell align="left" className="not-mobile">{row.creationDate}</TableCell>
                                                <TableCell align="left"><a className="x" onClick={()=>showRemove(row.id)}></a>&nbsp;&nbsp;&nbsp;&nbsp;<a className='user-list-edit' onClick={()=>edit(row.id)}></a></TableCell>
                                            </TableRow>);
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions >
                        <Grid container spacing={2} className="right-side action-margin mobile-buttons">
                            <a className="button button-template-mobile" fullWidth onClick={()=>handleClose()}>{t("template_text6")}</a>&nbsp;&nbsp;
                        </Grid>
                    </DialogActions>
                </div>
            </Dialog>
            <Dialog open={openDelete} onClose={()=>setOpenDelete(false)} style={{padding:"10px"}}>
                <DialogContent>
                    <DialogContentText>
                        {t("template_text7")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <a onClick={()=>setOpenDelete(false)} className="button button-cancel" fullWidth><i className="back"></i>{t("template_text8")}</a>
                        </Grid>
                        <Grid item xs>
                            <Button className="button full-width" onClick={()=>removeUser()}>
                                {isLoading && 
                                    <span className="loader"></span>
                                }
                                {!isLoading && 
                                    <span>{t("template_text9")}</span>
                                }
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
            <TemplateComponent isOpenDocuments={props.isOpenDocuments} setIsOpenDocuments={props.setIsOpenDocuments} template={editTemplate} refreshItems={refreshItems}></TemplateComponent>
        </div>
    );
}

export default TemplatesComponent;