import React, { useState, useEffect } from 'react';
import { Button,Grid,  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import { useForm } from "react-hook-form";
import {apiUsers} from '../../services/api/users/User';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { useTranslation } from "react-i18next";

const UsersComponent = (props) => {
    const [items, setItems] = useState([]);
    const [validationMessage, setValidationMessage] = useState('');
    const [isEditMode, setEditMode] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [id, setIdValue] = useState(-1);
    const { register,  getValues, setValue } = useForm();
    const { t, i18n, ready } = useTranslation();
    
    useEffect(() => {
        async function fetchData() {
            await refreshItems();
        }
        fetchData();
    }, []);

    const handleClose = () => {
        props.setIsOpenUsers(false);
    };

    const removeUser = async () => {
        await apiUsers.remove({id: id});
        await refreshItems();
        setOpenDelete(false);
    };

    const showRemove = (id) => {
        setIdValue(id);
        setOpenDelete(true);
    };

    const setUser = (id,email,firstName, lastName, company, city) => {
        setIdValue(id);
        setValue("email",email);
        setValue("firstName",firstName);
        setValue("lastName",lastName);
        setValue("company",company);
        setValue("city",city);
        setEditMode(true);
    }

    const cancel = () => {
        setValue("email","");
        setValue("firstName","");
        setValue("lastName","");
        setValue("company","");
        setValue("city","");
        setEditMode(false);
    }

    const refreshItems = async () => {
        let users = await apiUsers.users();
        if(users!==null)
        {
            setItems(users.items);
        }
    }

    const add = async () => {
        setEditMode(true);
        setIdValue(0);
    }

    const addUser = async () => {
        var email = getValues("email");
        var firstName = getValues("firstName");
        var lastName = getValues("lastName");
        var company = getValues("company");
        var city = getValues("city");
        if(email===null || email==='')
        {
            setValidationMessage(t("users_text21"));
            return;   
        }
        if(email.indexOf('@')<0)
        {
            setValidationMessage(t("users_text22"));
            return;   
        }
        if(firstName===null || firstName==='')
        {
            setValidationMessage(t("users_text23"));
            return;   
        }
        if(lastName===null || lastName==='')
        {
            setValidationMessage(t("users_text24"));
            return;   
        }
        let user = await apiUsers.saveUser({email:email, name: firstName, surname: lastName, company: company, roleId: 2, id: id, city:city});
        if(user!=null)
        {
            refreshItems();
            setEditMode(false);
        }
    }

    return (
        <div>
            <Dialog open={props.isOpenUsers} onClose={handleClose} style={{padding:"10px"}} maxWidth="lg" fullWidth>
                <div className='dialog-user'>
                    <DialogTitle>
                        {t("users_text1")}
                    </DialogTitle>
                    <DialogContent>
                    <DialogContentText>
                        <Grid container className="right-side">
                            <Button className="button button-white" onClick={()=>add()}>{t("users_text3")}</Button>
                        </Grid>
                        <br/>
                        <br/>
                        {isEditMode && 
                            <Grid container className="add-person-box">
                                <Grid item xs={12}>
                                    <h3>{id<=0 ? t("users_text3b"):t("users_text3c")}</h3>
                                </Grid>
                                {validationMessage && <Grid container>
                                    <div className="validation-error">{validationMessage}</div>
                                </Grid>
                                }
                                <Grid container spacing={1}>
                                    <Grid item xs={6}>
                                        <TextField {...register("firstName", { required: true })} placeholder={t("users_text6")}/>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField {...register("lastName", { required: true })} placeholder={t("users_text7")}/>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid item xs={6}>
                                        <TextField autoFocus {...register("email", { required: true })} placeholder={t("users_text4")}/>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField {...register("company", { required: true })} placeholder={t("users_text5")}/>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid item xs={6}>
                                        <TextField {...register("city", { required: true })} placeholder={t("users_text5b")}/>
                                    </Grid>
                                </Grid>
                                <Grid container xs={12} spacing={1}>
                                    <Grid item xs={6}>
                                        <Button className="button-person" onClick={()=>addUser()} fullWidth>{t("users_text8")}</Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button className="button-person" onClick={()=>cancel()} fullWidth style={{marginLeft:10}}>{t("users_text9")}</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                        {isEditMode && 
                            <br/>
                        }
                        <Grid>
                            <TableContainer className="document-list user user-list">
                                <Table aria-labelledby="tableTitle" aria-label="enhanced table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left">{t("users_text10")}</TableCell>
                                            <TableCell align="left">{t("users_text11")}</TableCell>
                                            <TableCell align="left">{t("users_text12")}</TableCell>
                                            <TableCell align="left">{t("users_text13")}</TableCell>
                                            <TableCell align="left">{t("users_text16")}</TableCell>
                                            <TableCell align="left"></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {items.map((row, index) => {
                                            return(
                                            <TableRow key={index}>
                                                <TableCell align="left">{row.name}</TableCell>
                                                <TableCell align="left">{row.surname}</TableCell>
                                                <TableCell align="left">{row.email}</TableCell>
                                                <TableCell align="left">{row.company}</TableCell>
                                                <TableCell align="left">{row.city}</TableCell>
                                                <TableCell align="left"><a className="x" onClick={()=>showRemove(row.id)}></a>&nbsp;&nbsp;&nbsp;&nbsp;<a className='user-list-edit' onClick={()=>setUser(row.id, row.email, row.name, row.surname, row.company, row.city)}></a></TableCell>
                                            </TableRow>);
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions >
                        <Grid container spacing={2} className="right-side action-margin">
                            <a className="button button-cancel" fullWidth onClick={()=>handleClose()}>{t("users_text20")}</a>&nbsp;&nbsp;
                        </Grid>
                    </DialogActions>
                </div>
            </Dialog>
            <Dialog open={openDelete} onClose={()=>setOpenDelete(false)} style={{padding:"10px"}}>
                <DialogContent>
                    <DialogContentText>
                        Czy na pewno chcesz usunąć użytkownika?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <a onClick={()=>setOpenDelete(false)} className="button button-cancel" fullWidth><i className="back"></i>Nie</a>
                        </Grid>
                        <Grid item xs>
                            <Button className="button full-width" onClick={()=>removeUser()}>
                                {isLoading && 
                                    <span className="loader"></span>
                                }
                                {!isLoading && 
                                    <span>Tak</span>
                                }
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default UsersComponent;